
// @flow
import React from 'react'
import cn from 'classnames'
import { Store } from 'Store.js'
import { withTranslation } from 'react-i18next'
import { Currency } from 'i18n/NumberFormatter'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'
import isShownNotExact from '../../util/isShownNotExact'
import Seedrandom from 'seedrandom'
import { useMediaQuery } from 'beautiful-react-hooks'
import LoadingIndicator from 'components/LoadingIndicator'
import transDomain from './translations/index.translations'

import Button from 'components/Button'

import SEDAN_FALLBACK from './images/fallback/Sedan.png'
import SUV_FALLBACK from './images/fallback/SUV & Crossover.png'
import TRUCK_FALLBACK from './images/fallback/Truck.png'

import styles from './styles.module.scss'

function ImageFallback ({ segment, className }) {
    let url = null
    switch (segment) {
        case 'Truck':
            url = TRUCK_FALLBACK
            break
        case 'SUV & Crossover':
            url = SUV_FALLBACK
            break
        case 'Car/Sedan':
            url = SEDAN_FALLBACK
            break
        default:
            url = SEDAN_FALLBACK
    }
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img style={{ marginTop: 'auto' }} className={className} src={url} />
}

function VehicleVoucherOption ({
    theme,
    voucherValue,
    startingPrice,
    name,
    year,
    make,
    segment,
    image,
    colorProp,
    title,
    selected,
    onClick,
    isVisible,
    isLoading
}) {
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const [imageError, setImageError] = React.useState(false)
    let [loaded, setLoaded] = React.useState(false)
    const isMobile = useMediaQuery('(max-width: 767px)')

    const color = React.useMemo(() => {
        if (colorProp) return colorProp
        const rng = new Seedrandom([make, name, year].join('-'))
        let color = theme.vehicleColors.sort(() => rng.quick() - 0.5)
        return Array.isArray(color) ? color.join(',') : color
    }, [make, name, year, colorProp, theme.vehicleColors])

    const modelNotExact = isShownNotExact(name, make, year)

    const handleImageLoad = React.useCallback(() => {
        setLoaded(true)
    }, [])

    const handleImageError = React.useCallback(() => {
        setImageError(true)
        setLoaded(true)
    }, [])

    const src = React.useMemo(() => {
        if (segment === 'Pre-Owned') {
            handleImageError(true)

            if (['GMC'].includes(make)) {
                return TRUCK_FALLBACK
            }
            if (['FORD'].includes(make)) {
                return SUV_FALLBACK
            }

            return SEDAN_FALLBACK
        }

        return image
    }, [make, segment, image, handleImageError])

    const handleOnClick = React.useCallback(() => {
        onClick(voucherValue, startingPrice, make, name, year, color)
    }, [make, name, onClick, startingPrice, voucherValue, year, color])

    return (
        <div className={cn(styles.carSummary, {
            [styles.selected]: selected,
            [styles.isVisible]: isVisible
        })} style={{
            '--primary-font': theme.primaryFont,
            '--primary-color': theme.primaryColor,
            '--secondary-color': theme.secondaryColor,
            backgroundColor: theme.backgroundColor
        }}>
            <div className={styles.voucherTag} style={{
                backgroundColor: theme.voucherTagBgColor
            }}>
                Voucher Value <strong>&nbsp;{currencyFormatter.format(voucherValue)}</strong>
            </div>

            <div className={styles.body}>
                <div className={styles.title} style={{
                    color: theme.titleColor
                }}>
                    {title}
                </div>

                <div
                    className={cn(styles.wrapper, styles.carContainer, {
                        [styles.loaded]: loaded,
                        [styles.largeTitle]: title.length >= 35
                    })}>
                    {imageError ? (
                        <ImageFallback className={styles.isFallback} segment={segment} />
                    ) : (
                        <>
                            {!loaded && <LoadingIndicator noAnimated small />}
                            <img
                                className={styles.img}
                                alt={`${year} ${name}`}
                                onLoad={handleImageLoad}
                                src={src}
                                onError={handleImageError}
                            />
                        </>
                    )}
                </div>
            </div>

            {modelNotExact && <span className={styles.shownMessage}>*Model shown is not exact</span>}

            <div className={styles.separator} style={{ backgroundColor: theme.lineColor }} />

            <div className={styles.footer}>
                {startingPrice > 0 && (
                    <div className={styles.price}>
                        Starting at price:
                        <strong>{currencyFormatter.format(startingPrice)}*</strong>
                    </div>
                )}

                <Button className={cn(styles.btn, {
                    [styles.isSelected]: selected,
                    [styles.isLoading]: isLoading && selected && !isMobile
                })} onClick={handleOnClick} disabled={isLoading && selected && !isMobile} loading={isLoading && selected && !isMobile}>
                    {selected ? 'Selected' : 'Select'}
                </Button>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(VehicleVoucherOption))
