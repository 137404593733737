const basePath = process.env.REACT_APP_VEHICLE_IMAGES_BASE_URL

const DISABLED_360_MODELS = {
    [`${basePath}exterior-360/Audi.A3%20Sedan.2022.jpg`]: true,
    [`${basePath}exterior-360/Audi.A5%20Cabriolet.2022.jpg`]: true,
    [`${basePath}exterior-360/Audi.A5%20Coupe.2022.jpg`]: true,
    [`${basePath}exterior-360/Audi.e-tron%20GT.2023.jpg`]: true,
    [`${basePath}exterior-360/Audi.R8%20Coupe.2022.jpg`]: true,
    [`${basePath}exterior-360/Audi.R8%20Spyder.2022.jpg`]: true,
    [`${basePath}exterior-360/Audi.RS%205%20Coupe.2022.jpg`]: true,
    [`${basePath}exterior-360/Audi.S3%20Sedan.2022.jpg`]: true,
    [`${basePath}exterior-360/Audi.S5%20Cabriolet.2022.jpg`]: true,
    [`${basePath}exterior-360/Audi.S8.2023.jpg`]: true,
    [`${basePath}exterior-360/Audi.TT%20Coupe.2022.jpg`]: true,
    [`${basePath}exterior-360/Audi.TT%20Roadster.2022.jpg`]: true,
    [`${basePath}exterior-360/Audi.TT%20RS%20Coupe.2022.jpg`]: true,
    [`${basePath}exterior-360/Audi.TTS%20Coupe.2022.jpg`]: true,
    [`${basePath}exterior-360/Buick.Enclave%20Avenir.2022.jpg`]: true,
    [`${basePath}exterior-360/Buick.Enclave%20Avenir.2023.jpg`]: true,
    [`${basePath}exterior-360/Buick.Enclave%20Sport%20Touring.2022.jpg`]: true,
    [`${basePath}exterior-360/Buick.Enclave%20Sport%20Touring.2023.jpg`]: true,
    [`${basePath}exterior-360/Buick.Enclave.2022.jpg`]: true,
    [`${basePath}exterior-360/Buick.Enclave.2023.jpg`]: true,
    [`${basePath}exterior-360/Buick.Encore%20GX%20Sport%20Touring.2023.jpg`]: true,
    [`${basePath}exterior-360/Buick.Encore%20GX.2023.jpg`]: true,
    [`${basePath}exterior-360/Buick.Encore.2022.jpg`]: true,
    [`${basePath}exterior-360/Buick.Envision%20Avenir.2023.jpg`]: true,
    [`${basePath}exterior-360/Buick.Envision%20Sport%20Touring.2023.jpg`]: true,
    [`${basePath}exterior-360/Buick.Envision.2023.jpg`]: true,
    [`${basePath}exterior-360/Buick.Envision.2024.jpg?fallbackToStillImage=1`]: true,
    [`${basePath}exterior-360/Chevrolet.Blazer.2023.jpg`]: true,
    [`${basePath}exterior-360/Chevrolet.Equinox.2022.jpg`]: true,
    [`${basePath}exterior-360/Chevrolet.Equinox.2023.jpg`]: true,
    [`${basePath}exterior-360/Chevrolet.Silverado%20HD.2023.jpg`]: true,
    [`${basePath}exterior-360/Chevrolet.Silverado%20HD.2024.jpg?fallbackToStillImage=1`]: true,
    [`${basePath}exterior-360/Chevrolet.Suburban.2022.jpg`]: true,
    [`${basePath}exterior-360/Chevrolet.Suburban.2023.jpg`]: true,
    [`${basePath}exterior-360/Chevrolet.Tahoe.2023.jpg`]: true,
    [`${basePath}exterior-360/Chevrolet.Trailblazer.2023.jpg`]: true,
    [`${basePath}exterior-360/Chevrolet.Traverse.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.Bronco%20Sport.2022.jpg`]: true,
    [`${basePath}exterior-360/Ford.Bronco%20Sport.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.Bronco.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.EcoSport.2022.jpg`]: true,
    [`${basePath}exterior-360/Ford.Edge.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.Escape%20SE%20Plug-in%20Hybrid.2022.jpg`]: true,
    [`${basePath}exterior-360/Ford.Escape.2022.jpg`]: true,
    [`${basePath}exterior-360/Ford.Escape.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.Expedition.2022.jpg`]: true,
    [`${basePath}exterior-360/Ford.Expedition.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.Explorer.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.F-150%20King%20Ranch.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.F-150%20Lariat.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.F-150%20Limited.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.F-150%20Platinum.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.F-150%20Tremor.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.F-150%20XL.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.F-150%20XLT.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.F-150.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.Maverick.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.Mustang%20Mach-E.2022.jpg`]: true,
    [`${basePath}exterior-360/Ford.Mustang.2022.jpg`]: true,
    [`${basePath}exterior-360/Ford.Mustang.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.Ranger.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.Super%20Duty.2023.jpg`]: true,
    [`${basePath}exterior-360/Ford.Transit.2023.jpg`]: true,
    [`${basePath}exterior-360/GMC.Acadia.2023.jpg`]: true,
    [`${basePath}exterior-360/GMC.Sierra%20HD.2022.jpg`]: true,
    [`${basePath}exterior-360/GMC.Sierra%20HD.2023.jpg`]: true,
    [`${basePath}exterior-360/GMC.Sierra.2022.jpg`]: true,
    [`${basePath}exterior-360/GMC.Sierra.2023.jpg`]: true,
    [`${basePath}exterior-360/GMC.Terrain.2022.jpg`]: true,
    [`${basePath}exterior-360/GMC.Terrain.2023.jpg`]: true,
    [`${basePath}exterior-360/GMC.Yukon.2023.jpg`]: true,
    [`${basePath}exterior-360/Kia.Sportage.2023.jpg`]: true,
    [`${basePath}exterior-360/Mazda.CX-3.2022.jpg`]: true,
    [`${basePath}exterior-360/Mazda.CX-5.2022.jpg`]: true,
    [`${basePath}exterior-360/Mazda.CX-9.2022.jpg`]: true,
    [`${basePath}exterior-360/Mazda.CX-30.2022.jpg`]: true,
    [`${basePath}exterior-360/Mazda.Mazda3%20Sport.2022.jpg`]: true,
    [`${basePath}exterior-360/Mazda.Mazda3.2022.jpg`]: true,
    [`${basePath}exterior-360/Mazda.MX-5%20RF.2022.jpg`]: true,
    [`${basePath}exterior-360/Mazda.MX-5%20Soft%20Top.2022.jpg`]: true,
    [`${basePath}exterior-360/Nissan.Frontier.2022.jpg`]: true,
    [`${basePath}exterior-360/Nissan.Kicks.2022.jpg`]: true,
    [`${basePath}exterior-360/Nissan.Maxima.2022.jpg`]: true,
    [`${basePath}exterior-360/Nissan.Qashqai.2022.jpg`]: true,
    [`${basePath}exterior-360/Nissan.Versa.2022.jpg`]: true,
    [`${basePath}exterior-360/Ram.Chassis%20Cab.2022.jpg`]: true,
    [`${basePath}exterior-360/Toyota.4Runner.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Camry%20Hybrid.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Camry.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Corolla%20Cross.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Corolla%20Hatchback.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Corolla%20Hybrid.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.GR%20Corolla.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.GR%20Supra.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.GR86.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Highlander%20Hybrid.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Highlander.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Prius.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.RAV4%20Hybrid.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.RAV4.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Sequoia.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Sienna.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Tacoma.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Tundra%20Hybrid.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Tundra.2023.jpg`]: true,
    [`${basePath}exterior-360/Toyota.Venza.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.Tucson.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.Kona%20Electric.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.Elantra.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.IONIQ%205.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.Kona.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.Sonata%20N.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.Santa%20Cruz.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.Kona%20N.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.Sonata%20Hybrid.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.Elantra%20N.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.Sonata.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.Santa%20Fe.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.Elantra%20Hybrid.2023.jpg`]: true,
    [`${basePath}exterior-360/Hyundai.Venue.2023.jpg`]: true,
    [`${basePath}exterior-360/Nissan.Armada.2023.jpg`]: true,
    [`${basePath}exterior-360/Nissan.Murano.2023.jpg`]: true,
    [`${basePath}exterior-360/Nissan.Pathfinder.2023.jpg`]: true,
    [`${basePath}exterior-360/Nissan.Rogue.2023.jpg`]: true,
    [`${basePath}exterior-360/Nissan.Frontier.2023.jpg`]: true
}

export default function is360Disabled (url) {
    return DISABLED_360_MODELS[url]
}
