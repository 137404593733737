// @flow
import React from 'react'

import { Store } from 'Store.js'
import { withTranslation } from 'react-i18next'
import { Currency, Percent } from 'i18n/NumberFormatter'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'
import MediaQuery from 'react-responsive'
import LoadingIndicator from 'components/LoadingIndicator'

import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

const maxFrameHeight = 480
const maxFrameCount = 36
const minFrameCount = 1
const maxRangeToSlide = 10
const defaultRangeToSlide = 2.5

function WideCarView ({
    theme,
    startingPrice,
    title,
    voucherValue,
    uri,
    fallbackImage,
}) {
    const [imageUrl, setImageUrl] = React.useState(uri)
    const ref = React.useRef(null)
    const { state } = React.useContext(Store)
    const [ swiped, setSwiped ] = React.useState(false)
    const [ xFrameCount, setXframeCount ] = React.useState(maxFrameCount)
    const [ rangeToSlide, setRangeToSlide ] = React.useState(defaultRangeToSlide)
    const [ swiping, setSwiping ] = React.useState(false)
    const [ clientXBeging, setClientXBeging ] = React.useState(0)
    const [ step, setStep ] = React.useState(0)
    const [ mouseDown, setMouseDown ] = React.useState(false)
    const [ loadedUri, setLoadedUri ] = React.useState(false)
    const [ frameH, setFrameH ] = React.useState(0)

    const percentFormatter = Percent(state.locale)
    const currencyFormatter = Currency(state.currency, state.locale)

    const incentives = state.campaign.incentives?.manufacturer

    const handleRange = React.useCallback(event => {
        setStep(event.target.value - 1)
    }, [])

    const handleOnTouchStart = React.useCallback(e => {
        setSwiped(false)
        setSwiping(false)
        setClientXBeging(e.touches[0]?.clientX)
        setMouseDown(true)
    }, [])

    const handleOnTouchMove = React.useCallback(e => {
        setSwiping(true)
        let currentStep = step
        const clientX = e.touches[0]?.clientX
        const direction = clientXBeging - clientX
        if (clientXBeging && mouseDown) {
            if (Math.abs(direction) >= rangeToSlide) {
                if (direction > 0) {
                    if (currentStep + 1 >= xFrameCount) {
                        setStep(xFrameCount - 1)
                    } else {
                        setStep(currentStep + 1)
                    }
                } else {
                    if (currentStep - 1 <= 0) {
                        setStep(0)
                    } else {
                        setStep(currentStep - 1)
                    }
                }
                setClientXBeging(e.touches[0]?.clientX)
            }
        }
    }, [step, clientXBeging, mouseDown, rangeToSlide, xFrameCount])

    const handleOnTouchUp = React.useCallback(e => {
        const clientX = e.touches[0]?.clientX
        const direction = clientXBeging - clientX
        if (swiping && !swiped) {
            let currentStep = step
            if (direction > 0) {
                if (currentStep + 1 >= xFrameCount) {
                    setStep(xFrameCount - 1)
                } else {
                    setStep(currentStep + 1)
                }
            } else {
                if (currentStep - 1 <= 0) {
                    setStep(0)
                } else {
                    setStep(currentStep - 1)
                }
            }

            setSwiping(false)
            setSwiped(true)
            setClientXBeging(null)
            setMouseDown(false)
        }
    }, [ swiping, step, swiped, clientXBeging, xFrameCount ])

    const handleOnMouseDown = React.useCallback(e => {
        setSwiped(false)
        setSwiping(false)
        setClientXBeging(e.clientX)
        setMouseDown(true)
    }, [])

    const handleMouseMove = React.useCallback(e => {
        setSwiping(true)
        let currentStep = step
        const clientX = e.clientX
        const direction = clientXBeging - clientX
        if (clientXBeging && mouseDown) {
            if (Math.abs(direction) >= rangeToSlide) {
                if (direction > 0) {
                    if (currentStep + 1 >= xFrameCount) {
                        setStep(xFrameCount - 1)
                    } else {
                        setStep(currentStep + 1)
                    }
                } else {
                    if (currentStep - 1 <= 0) {
                        setStep(0)
                    } else {
                        setStep(currentStep - 1)
                    }
                }
                setClientXBeging(e.clientX)
            }
        }
    }, [step, clientXBeging, mouseDown, rangeToSlide, xFrameCount])

    const handleMouseUp = React.useCallback(e => {
        const clientX = e.clientX
        const direction = clientXBeging - clientX
        if (swiping && !swiped) {
            let currentStep = step
            if (direction > 0) {
                if (currentStep + 1 >= xFrameCount) {
                    setStep(xFrameCount - 1)
                } else {
                    setStep(currentStep + 1)
                }
            } else {
                if (currentStep - 1 <= 0) {
                    setStep(0)
                } else {
                    setStep(currentStep - 1)
                }
            }

            setSwiping(false)
            setSwiped(true)
            setClientXBeging(null)
            setMouseDown(false)
        }
    }, [ xFrameCount, swiping, step, swiped, clientXBeging ])

    React.useEffect(() => {
        setImageUrl(uri)

        setSwiped(false)
        setSwiping(false)
        setClientXBeging(0)
        setStep(0)
        setMouseDown(false)
        setLoadedUri(false)
        setFrameH(0)
    }, [ uri ])

    React.useEffect(() => {
        const refEvent = () => {
            if (ref && loadedUri && ref.current) {
                const xFrame = ref.current.naturalHeight / maxFrameHeight

                if (xFrame !== maxFrameCount) {
                    setXframeCount(xFrame)
                    setRangeToSlide(maxRangeToSlide)
                } else {
                    setXframeCount(maxFrameCount)
                    setRangeToSlide(defaultRangeToSlide)
                }

                setFrameH(ref.current.height / xFrame)
            }
        }

        setTimeout(refEvent, 250)

        window.addEventListener('orientationchange', refEvent)
        window.addEventListener('resize', refEvent)

        return () => {
            window.removeEventListener('resize', refEvent)
            window.removeEventListener('orientationchange', refEvent)
        }
    }, [ ref, loadedUri ])

    const handleLoadedUri = React.useCallback(() => {
        setLoadedUri(true)
    }, [ setLoadedUri ])

    const handleOnError = React.useCallback(e => {
        setImageUrl(fallbackImage)
        setLoadedUri(false)
        console.warn(e)
    }, [fallbackImage])

    return (
        <div className={styles.wideCarView} style={{
            '--primary-font': theme.primaryFont,
            '--primary-color': theme.primaryColor
        }}>

            <MediaQuery maxWidth={1022}>
                <div className={styles.title}>{title}</div>
            </MediaQuery>

            <div className={styles.car}>
                <div
                    className={styles.imgSet}
                    >
                    <img
                        style={{
                            top: (step * -frameH),
                            opacity: loadedUri ? 1 : 0.25
                        }}
                        key={imageUrl}
                        ref={ref}
                        src={imageUrl}
                        alt={title}
                        onLoad={handleLoadedUri}
                        onError={handleOnError}
                    />

                    {xFrameCount > 1 && <div
                        className={styles.controllerArea}

                        onMouseDownCapture={handleOnMouseDown}
                        onMouseMoveCapture={handleMouseMove}
                        onBlur={handleMouseUp}
                        onMouseOut={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                        onMouseUpCapture={handleMouseUp}

                        onTouchStart={handleOnTouchStart}
                        onTouchMove={handleOnTouchMove}
                        onTouchCancel={handleOnTouchUp}
                        onTouchEnd={handleOnTouchUp}
                    />}
                </div>

                {!loadedUri && <LoadingIndicator />}

                {xFrameCount > 1 &&
                    <>
                        <input
                        value={step + 1}
                        className={styles.bar}
                        type='range'
                        onChange={handleRange}
                        min={minFrameCount}
                        max={xFrameCount}
                        style={{
                            '--thumb-width': `${100 / 8}%`,
                            '--thumb-color': theme.secondaryColor
                        }} /><div className={styles.info}>
                            Get a 360° look at the {title}
                        </div>
                    </>
                }
            </div>

            <MediaQuery maxWidth={1022}>
                <div className={styles.separatorLine} />
            </MediaQuery>

            <div className={styles.summary}>
                <MediaQuery minWidth={1023}>
                    <div className={styles.title}>{title}</div>
                </MediaQuery>

                <div className={styles.content}>
                    {startingPrice > 0 && (
                        <div className={styles.contentItem}>
                            Starting at price:
                            <strong>{currencyFormatter.format(startingPrice)}*</strong>
                        </div>
                    )}

                    <div className={styles.contentItem} style={{
                        color: theme.secondaryColor
                    }}>
                        Voucher value:
                        <strong>{currencyFormatter.format(voucherValue)}</strong>
                    </div>
                </div>

                <div className={styles.separator}>
                    PLUS
                </div>

                {incentives && (
                    <div className={styles.incentivesWrapper}>
                        {incentives.interestRate !== null && (
                            <div className={styles.rates}>
                                Rates as low as <strong>{percentFormatter.format((Number.parseFloat(incentives.interestRate).toPrecision(3) / 100) || 0)}</strong>
                            </div>
                        )}

                        {(incentives.cashBack > 0) && (
                            <div className={styles.incentives}>
                                Cash incentives up to <strong>{currencyFormatter.format(incentives.cashBack)}*</strong>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(WideCarView))
