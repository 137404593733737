
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsdd3e6decf0a62a6104206a9d52636eecen_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'dd3e6decf0a62a6104206a9d52636eec', flattenObject(nsdd3e6decf0a62a6104206a9d52636eecen_US), true, true);
        
            import nsdd3e6decf0a62a6104206a9d52636eecen from './en.yaml'
            i18n.addResourceBundle('en', 'dd3e6decf0a62a6104206a9d52636eec', flattenObject(nsdd3e6decf0a62a6104206a9d52636eecen), true, true);
        
        export default 'dd3e6decf0a62a6104206a9d52636eec';
    