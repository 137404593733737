
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns82cf83e74111cfd4570bce1e7d7f7a63en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '82cf83e74111cfd4570bce1e7d7f7a63', flattenObject(ns82cf83e74111cfd4570bce1e7d7f7a63en_US), true, true);
        
            import ns82cf83e74111cfd4570bce1e7d7f7a63en from './en.yaml'
            i18n.addResourceBundle('en', '82cf83e74111cfd4570bce1e7d7f7a63', flattenObject(ns82cf83e74111cfd4570bce1e7d7f7a63en), true, true);
        
        export default '82cf83e74111cfd4570bce1e7d7f7a63';
    