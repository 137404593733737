// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes'
import type { Theme } from './themes/__supportedThemes'

import transDomain from './translations/index.translations'

import { Store } from 'Store'
import TermsAndConditions from 'components/TermsAndConditions'

type Props = {
    theme: Theme
}

function Footer (props: Props) {
    const { state } = React.useContext(Store)
    const { t, theme } = props

    const clientAddress = React.useMemo(() => {
        return state.campaign.client.address ? [
            [state.campaign.client.address.line1, state.campaign.client.address.line2],
            state.campaign.client.address.city,
            [state.campaign.client.address.province, state.campaign.client.address.postalCode]
        ].filter(Boolean).map(i => Array.isArray(i) ? i.filter(Boolean).join(' ') : i).join(', ') : null
    }, [state.campaign.client.address])

    return (
        <div className={styles.container}
            style={{
                color: theme.color,
                backgroundColor: theme.background,
                '--primary-font': theme.primaryFont
            }}>
            <div className={styles.innerContainer}>
                <div className={styles.leftContent}>
                    <p className={styles.dealershipInfo} style={{ '--dealership-color': theme.dealershipColor }}>
                        {state.campaign.client.name}&nbsp;

                        {clientAddress && `| ${clientAddress} `}{state.campaign.contactInfo.phone && `| ${state.campaign.contactInfo.phone}`}

                        {state.campaign.client.website && <>&nbsp;|&nbsp;<a href={state.campaign.client.website}>{state.campaign.client.website.replace(/(^\w+:|^)\/\//, '')}</a></>}
                    </p>

                    <p className={styles.disclaimer} style={{
                        color: theme.disclaimerColor
                    }}>{t('disclaimer')}</p>

                    {state.campaign.termsAndConditions && (
                        <TermsAndConditions termsAndConditions={state.campaign.termsAndConditions}/>
                    )}
                </div>

                <img
                    className={styles.logo}
                    src={theme.logo}
                    alt={theme.logoAlt}
                    width={theme.logoWidth}
                    height={theme.logoHeight}
                />
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Footer))
