// @flow
import * as React from 'react'
import RegistrationForm from 'components/RegistrationForm'

import { makeDateFormatters } from 'i18n/DateFormat'

import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import SummaryFooter from 'components/SummaryFooter'

import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

function RegisterCode ({
    t,
    theme
}) {
    const { state } = React.useContext(Store)

    const dateFormatter = React.useMemo(() => {
        return makeDateFormatters(state.locale, 'long', null)
    }, [state.locale])

    return (
        <div className={styles.registerCode} style={{
            '--primary-font': theme.primaryFont
        }}>
            <h3
                className={styles.title}
                style={{
                    color: theme.titleColor
                }}
            >
                <Trans i18nKey="title" t={t}>
                    Enter your customer code <br /> to access the offer portal.
                </Trans>
            </h3>

            <span className={styles.expireInfo} style={{ color: theme.expireInfoColor }}>
                Current offers expire <strong>{dateFormatter.format(state.campaign.expiryDate, 'long')}.</strong>
            </span>

            <div className={styles.formContainer}>
                <RegistrationForm />
            </div>

            <SummaryFooter />
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegisterCode))
