import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import { Store } from 'Store'
import MediaQuery from 'react-responsive'

import Seedrandom from 'seedrandom'

import * as Form from 'components/Form'
import BottomBar from 'components/BottomBar'
import CarView from 'components/CarView'

import VehicleVoucherOption from 'components/VehicleVoucherOption'

import supportedThemes from './themes/__supportedThemes'
import transDomain from './translations/index.translations'

import withForm from 'components/Form/Helpers/FormHOC'

import styles from './styles.module.scss'

const INITIAL_VALUE = 'Filter all types'
const INITIAL_MAKE_VALUE = 'Filter all makes'

function Step2B ({ theme, isLoading, handleFieldsChange, values }) {
    const { state } = React.useContext(Store)
    const { t } = useTranslation(transDomain)
    const [segment, setSegment] = React.useState(null)
    const [make, setMake] = React.useState(null)
    const [model, setModel] = React.useState(null)
    const [isModelLoading, setIsLoading] = React.useState(true)

    const campaignModels = React.useMemo(() => {
        return state.campaign.models.map(m => {
            let model = { ...m }

            if (state.campaign.incentives.voucher.type !== 'variable') {
                model.voucherValue = state.campaign.incentives.voucher.value
            }

            if (
                model.make === values.newVehicleMake &&
                model.name === values.newVehicleModel &&
                model.year === values.newVehicleModelYear
            ) {
                model.color = values.newVehicleModelColor
            }

            return model
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.campaign.incentives.voucher.type, state.campaign.incentives.voucher.value, state.campaign.models, values.newVehicleMake])

    const makes = React.useMemo(() => {
        const make = {}
        campaignModels.forEach(i => {
            make[i.make] = i.make
        })
        return Object.keys(make)
    }, [campaignModels])

    const segments = React.useMemo(() => {
        const segments = campaignModels.reduce((ss: Set, model) => {
            if (model.segment !== null) {
                ss.add(model.segment)
            }
            return ss
        }, new Set())
        return Array.from(segments).sort((a, b) => {
            if (a === 'Pre-Owned') {
                return 1
            }

            if (b === 'Pre-Owned') {
                return -1
            }

            return a < b ? -1 : 1
        })
    }, [campaignModels])

    const models = React.useMemo(() => {
        setModel(null)
        return campaignModels.filter(m => {
            if (segment && m.segment !== segment) return false
            return !(make && m.make !== make)
        })
    }, [campaignModels, segment, make])

    const handleSelect = React.useCallback(
        async (make, model, year, color) => {
            handleFieldsChange({
                newVehicleMake: make,
                newVehicleModel: model,
                newVehicleModelYear: year,
                newVehicleModelColor: color
            })
        },
        [handleFieldsChange]
    )

    const defaultModel = React.useMemo(() => {
        return models.find(model => model.name === values.newVehicleModel && model.year === values.newVehicleModelYear)
    }, [models, values.newVehicleModel, values.newVehicleModelYear])

    React.useEffect(() => {
        if (!models[0] || model) return () => {}

        if (defaultModel) {
            let color = null

            if (!defaultModel.color) {
                if (values.newVehicleModelColor) {
                    color = values.newVehicleModelColor
                } else {
                    const rng = new Seedrandom(
                        [
                            defaultModel.make,
                            defaultModel.name,
                            defaultModel.year
                        ].join('-')
                    )

                    color = theme.vehicleColors.sort(() => rng.quick() - 0.5)
                    color = Array.isArray(color) ? color.join(',') : color
                }
            } else {
                color = defaultModel.color
            }

            setModel({ ...defaultModel, color })
        } else {
            const preSelectedModel = models[0]
            let color = null

            if (!preSelectedModel.color) {
                const rng = new Seedrandom(
                    [
                        preSelectedModel.make,
                        preSelectedModel.name,
                        preSelectedModel.year
                    ].join('-')
                )

                color = theme.vehicleColors.sort(() => rng.quick() - 0.5)
                color = Array.isArray(color) ? color.join(',') : color
            } else {
                color = preSelectedModel.color
            }

            setModel({ ...preSelectedModel, color })
        }
    // eslint-disable-next-line
  }, [models, theme.vehicleColors, values.newVehicleModelColor])

    const handleOnSelect = React.useCallback((voucherValue, startingPrice, make, name, year, propColor) => {
        setIsLoading(true)
        const rng = new Seedrandom(
            [
                make,
                name,
                year
            ].join('-')
        )
        let color = theme.vehicleColors.sort(() => rng.quick() - 0.5)
        color = Array.isArray(color) ? color.join(',') : color

        setModel({
            voucherValue,
            startingPrice,
            make,
            name,
            year,
            color: propColor || color
        })
    }, [theme.vehicleColors, setIsLoading])

    React.useEffect(() => {
        if (model) {
            const { make, name, year, color } = model
            handleSelect(make, name, year, color)
        }
    }, [model, handleSelect, state.lead])

    const handleOnLoad = React.useCallback(() => {
        setIsLoading(false)
    }, [setIsLoading])

    const isVisible = React.useCallback((
        make,
        segment,
        voucherValue,
        name,
        startingPrice,
        year
    ) => {
        return models.find(m => (m.make === make && m.segment === segment && m.voucherValue === voucherValue && m.name === name && (m.startingPrice || 0) === (startingPrice || 0) && m.year === year))
    }, [models])

    return (
        <>
            <div className={styles.layoutWrapper}>
                <div className={styles.topArea}>
                    <h2
                        className={styles.heading}
                        style={{
                            color: theme.titleColor
                        }}
                    >
                        {t('heading')}
                    </h2>

                    <div className={styles.dropdownContainer}>
                        <div className={styles.dropdown}>
                            <Form.Row>
                                <Form.RowColumns>
                                    <Form.RowColumn size={makes.length > 1 ? 0.5 : 1}>
                                        <Form.Field>
                                            <Form.Dropdown
                                                options={segments}
                                                trans={t}
                                                placeholder={INITIAL_VALUE}
                                                onChange={(seg) => setSegment(seg)}
                                                defaultValue={segment || INITIAL_VALUE}
                                                required={false}
                                            />
                                        </Form.Field>
                                    </Form.RowColumn>
                                    {makes.length > 1 && (
                                        <Form.RowColumn size={0.5}>
                                            <Form.Field>
                                                <Form.Dropdown
                                                    options={makes}
                                                    trans={t}
                                                    placeholder={INITIAL_MAKE_VALUE}
                                                    onChange={setMake}
                                                    defaultValue={make || INITIAL_MAKE_VALUE}
                                                    required={false}
                                                />
                                            </Form.Field>
                                        </Form.RowColumn>
                                    )}
                                </Form.RowColumns>
                            </Form.Row>
                        </div>
                    </div>
                </div>

                <div className={styles.stepContainer}>
                    <div className={styles.modelOptions}>
                        <MediaQuery minWidth={1023}>
                            <div className={styles.options}>
                                <Form.Row>
                                    <Form.RowColumns>
                                        {campaignModels.map(({
                                            make,
                                            segment,
                                            voucherValue,
                                            name,
                                            startingPrice,
                                            year,
                                            color,
                                            images,
                                        }, i) => (
                                            <Form.RowColumn size={0.5} style={{
                                                display: isVisible(
                                                    make,
                                                    segment,
                                                    voucherValue,
                                                    name,
                                                    startingPrice,
                                                    year
                                                ) ? 'inline-block' : 'none'
                                            }} key={i}>
                                                <VehicleVoucherOption
                                                    key={i}
                                                    index={i}
                                                    make={make}
                                                    segment={segment}
                                                    startingPrice={startingPrice || 0}
                                                    isVisible={true}
                                                    name={name}
                                                    year={year}
                                                    image={images.front_34.m}
                                                    voucherValue={voucherValue}
                                                    colorProp={color}
                                                    selected={model ? (model.name === name && model.year === year) : false}
                                                    isLoading={isModelLoading}
                                                    onClick={handleOnSelect}
                                                    title={`${year || ''} ${name} ${segment === 'Pre-Owned' ? make : ''}`}
                                                />
                                            </Form.RowColumn>
                                        ))}
                                    </Form.RowColumns>
                                </Form.Row>
                            </div>
                        </MediaQuery>
                    </div>

                    <MediaQuery minWidth={767}>
                        <div className={styles.carView}>
                            <CarView
                                title={model ? `${model.year || ''} ${model.name} ${segment === 'Pre-Owned' ? model.make : ''}` : '...'}
                                startingPrice={model ? model.startingPrice : 0}
                                segment={model ? model.segment : null}
                                make={model ? model.make : null}
                                name={model ? model.name : null}
                                year={model ? model.year : null}
                                image={model ? state.campaign.models.find(m => m.make === model.make && m.name === model.name && m.year === model.year)?.images.front_34.m : null}
                                voucherValue={model ? model.voucherValue : 0}
                                color={model ? model.color : null}
                                onLoad={handleOnLoad}
                            />
                        </div>
                    </MediaQuery>
                </div>
            </div>

            <MediaQuery maxWidth={1023}>
                <div className={styles.slidesContainer}>
                    {campaignModels.map(({
                        make,
                        segment,
                        voucherValue,
                        name,
                        startingPrice,
                        year,
                        color,
                        images,
                    }, i) => (
                        <VehicleVoucherOption
                            key={i}
                            index={i}
                            make={make}
                            segment={segment}
                            image={images.front_34.m}
                            startingPrice={startingPrice || 0}
                            isVisible={isVisible(
                                make,
                                segment,
                                voucherValue,
                                name,
                                startingPrice,
                                year
                            )}
                            name={name}
                            year={year}
                            voucherValue={voucherValue}
                            selected={model ? (model.name === name && model.year === year) : false }
                            isLoading={isModelLoading}
                            onClick={handleOnSelect}
                            colorProp={color}
                            title={`${year || ''} ${name} ${segment === 'Pre-Owned' ? make : ''}`}
                        />
                    ))}
                    <div style={{ maxWidth: '1px', minWidth: '1px' }} />
                </div>
                <div />
            </MediaQuery>

            <BottomBar
                step={1}
                isLoading={isLoading}
                fixed
            />
        </>
    )
}

export default withForm()(withTheme(supportedThemes)(Step2B))
