
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns5e55b3f54b8d9b8478d8dad38e535e56en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '5e55b3f54b8d9b8478d8dad38e535e56', flattenObject(ns5e55b3f54b8d9b8478d8dad38e535e56en_US), true, true);
        
            import ns5e55b3f54b8d9b8478d8dad38e535e56en from './en.yaml'
            i18n.addResourceBundle('en', '5e55b3f54b8d9b8478d8dad38e535e56', flattenObject(ns5e55b3f54b8d9b8478d8dad38e535e56en), true, true);
        
        export default '5e55b3f54b8d9b8478d8dad38e535e56';
    