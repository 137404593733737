// @flow

import * as React from 'react'
import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import OfferTag from 'components/OfferTag'
import cn from 'classnames'

import { Store } from 'Store.js'

import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

function SummaryFooter (props) {
    const { state } = React.useContext(Store)

    const clientAddress = React.useMemo(() => {
        return state.campaign.client.address ? [
            [state.campaign.client.address.line2, state.campaign.client.address.line1],
            state.campaign.client.address.city,
            [state.campaign.client.address.province, state.campaign.client.address.postalCode]
        ].filter(Boolean).map(i => Array.isArray(i) ? i.filter(Boolean).join(' ') : i).join(', ') : null
    }, [state.campaign.client.address])

    return (
        <div className={styles.summaryFooter} style={{
            '--line-color': props.theme.lineColor,
            '--secondary-font': props.theme.secondaryFont
        }}>
            <div className={cn({
                [styles.inline]: props.theme.inline
            })}>
                <div
                    className={styles.logo}
                    style={{
                        backgroundImage: `url(${props.theme.logo})`,
                        width: props.theme.logoWidth,
                        height: props.theme.logoHeight
                    }}
                />
                <OfferTag />
            </div>

            <div className={styles.disclaimer}>
                <span>
                    <strong>{state.campaign.client.name}</strong>
                    {clientAddress && ` | ${clientAddress} `}{state.campaign.contactInfo.phone && ` ${state.campaign.contactInfo.phone}`}
                    {state.campaign.client.website && <>&nbsp;|&nbsp;<a href={state.campaign.client.website}>{state.campaign.client.website.replace(/(^\w+:|^)\/\//, '')}</a></>}
                </span>

                <strong className={styles.summaryFooterDisclaimer}>By submitting this information, you consent <br /> to us communicating with you.</strong>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(SummaryFooter))
