
// @flow
import React from 'react'
import cn from 'classnames'
import { Store } from 'Store.js'
import { withTranslation } from 'react-i18next'
import { Currency } from 'i18n/NumberFormatter'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'
import { Redirect, withRouter } from 'react-router-dom'

import transDomain from './translations/index.translations'

import Button from 'components/Button'

import SEDAN_FALLBACK from './images/fallback/Sedan.png'
import SUV_FALLBACK from './images/fallback/SUV & Crossover.png'
import TRUCK_FALLBACK from './images/fallback/Truck.png'

import styles from './styles.module.scss'

function ImageFallback ({ segment }) {
    let url = null
    switch (segment) {
        case 'Truck':
            url = TRUCK_FALLBACK
            break
        case 'SUV & Crossover':
            url = SUV_FALLBACK
            break
        case 'Car/Sedan':
            url = SEDAN_FALLBACK
            break
        default:
            url = SEDAN_FALLBACK
    }
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img style={{ marginTop: 'auto', left: 0, top: '25%' }} src={url} />
}

function CarSummary ({
    theme,
    voucherValue,
    startingPrice,
    name,
    year,
    make,
    segment,
    image,
    location
}) {
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const [loading, setLoading] = React.useState(false)
    const [imageError, setImageError] = React.useState(false)
    let [loaded, setLoaded] = React.useState(false)

    const handleImageLoad = React.useCallback(() => {
        setLoaded(true)
    }, [])

    const handleImageError = React.useCallback(() => {
        setImageError(true)
        setLoaded(true)
    }, [])

    const handleOnClick = React.useCallback(() => {
        setLoading(true)
    }, [])

    const title = `${year || ''} ${name} ${segment === 'Pre-Owned' ? make : ''}`

    if (loading) {
        return <Redirect to={`${location.pathname.replace('/step-2', '')}${location.search}`} push />
    }

    return (
        <div className={styles.carSummary} style={{
            '--primary-font': theme.primaryFont,
            '--primary-color': theme.primaryColor,
            '--secondary-color': theme.secondaryColor,
            backgroundColor: theme.backgroundColor
        }}>
            <div className={styles.voucherTag} style={{
                backgroundColor: theme.voucherTagBgColor
            }}>
                Voucher Value <strong>&nbsp;{currencyFormatter.format(voucherValue)}</strong>
            </div>

            <div className={styles.body}>
                <div className={styles.title} style={{
                    color: theme.titleColor
                }}>
                    {title}
                </div>

                <div
                    className={cn(styles.wrapper, styles.carContainer, {
                        [styles.loaded]: loaded,
                        [styles.largeTitle]: title.length >= 35
                    })}>
                    {imageError ? (
                        <ImageFallback segment={segment} />
                    ) : (
                        <>
                            <Loader color={theme.loaderColor} />
                            <img
                                className={styles.img}
                                alt={`${year} ${name}`}
                                onLoad={handleImageLoad}
                                src={image}
                                onError={handleImageError}
                            />
                        </>
                    )}
                </div>
            </div>

            <div className={styles.separator} style={{ backgroundColor: theme.lineColor }} />

            <div className={cn(styles.footer, {
                [styles.centerItems]: startingPrice === 0,
            })}>
                {startingPrice > 0 && (
                    <div className={styles.price}>
                        Starting at price:
                        <strong>{currencyFormatter.format(startingPrice)}*</strong>
                    </div>
                )}

                <Button className={cn(styles.btn, {
                    [styles.isLoading]: loading
                })} loading={loading} disabled={loading} onClick={handleOnClick}>
                    Change your selection
                </Button>
            </div>
        </div>
    )
}
const Loader = (props: { color: string }) => {
    return (
        <div className={styles.loader} style={{ borderTopColor: props.color }} />
    )
}

export default withRouter(withTranslation(transDomain)(withTheme(supportedThemes)(CarSummary)))
